import React from 'react';
import styled from '@emotion/styled';

const List = styled.ul`
    list-style: disc;
    padding-right: 25px;
    line-height: normal;
    font-size: 1.125rem;
`;

interface TextListProps {
    items: string[];
    className?: string;
}

function TextList(props: TextListProps) {
    const { items, className } = props;

    return (
        <List className={className}>
            {items.map((item) => (
                <li key={item}>{item}</li>
            ))}
        </List>
    );
}

export { TextList };
