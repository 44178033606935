import React from 'react';
import { PageProps } from 'gatsby';
import { Container } from '../components/Layout/Container';
import styled from '@emotion/styled';
import { Package } from '../components/Package';
import { TextList } from '../components/TextList';
import { ReadMore } from '../components/ReadMore';
import { Markdown } from '../components/Layout/Markdown';
import { ImageWithSize, PackagesPageData } from '../types';
import { SubTitle, Title } from '../components/Layout/Title';
import {
    aboveMobile,
    belowEqualMobile,
    useIsMobile,
} from '../services/mediaquery';

const PackagesList = styled.div`
    margin-top: 30px;
    ${aboveMobile} {
        display: flex;
        justify-content: center;
    }
`;

const StyledPackage = styled(Package)`
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 1px 1px 16px 1px #cccccca8;
    ${belowEqualMobile} {
        margin: 40px auto;
    }
    ${aboveMobile} {
        margin: 0 12px;
        &:first-of-type {
            margin-right: 0;
        }
        &:last-of-type {
            margin-left: 0;
        }
    }
`;

const NotesContainer = styled.div`
    margin-top: 40px;
`;

const NotesList = styled(TextList)`
    margin-top: 10px;
`;

// const Link = styled(Markdown.withComponent('a'))`
//     margin-top: 30px;
//     display: block;
//     margin-bottom: 30px;
//     text-decoration: underline;
// `;

function PackagesPage(
    props: PageProps<object, PackagesPageData<ImageWithSize>>,
) {
    const {
        pageContext: { title, description, packages, notes },
    } = props;

    const isMobile = useIsMobile();

    return (
        <Container>
            {title && <Title>{title}</Title>}
            {description &&
                (isMobile ? (
                    <ReadMore
                        maxHeight={200}
                        readMoreLabel="(קרא עוד)"
                        readLessLabel="(סגור)"
                    >
                        <Markdown>{description}</Markdown>
                    </ReadMore>
                ) : (
                    <Markdown>{description}</Markdown>
                ))}
            {/* {link?.href && <Link href={link.href}>{link.text}</Link>} */}
            <PackagesList>
                {packages.map((packagePage) => (
                    <StyledPackage
                        key={packagePage.name}
                        name={packagePage.name}
                        image={packagePage.image.medium}
                        bullets={packagePage.bullets}
                        price={packagePage.price}
                    />
                ))}
            </PackagesList>
            <NotesContainer>
                <SubTitle>חשוב שתדעו:</SubTitle>
                <NotesList items={notes} />
            </NotesContainer>
        </Container>
    );
}

export default PackagesPage;
