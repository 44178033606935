import styled from '@emotion/styled';
import { aboveMobile, belowEqualMobile } from '../../services/mediaquery';

const Title = styled.h1`
    font-family: 'Amatic SC', cursive;
    font-size: 2.875rem;
    font-weight: 700;
    margin-bottom: 20px;
    ${belowEqualMobile} {
        text-align: center;
    }
    ${aboveMobile} {
        margin-top: 44px;
    }
`;

const SubTitle = styled.div`
    font-family: 'Amatic SC', cursive;
    font-weight: 700;
    font-size: 2.125rem;
    margin-bottom: 10px;
    ${belowEqualMobile} {
        text-align: center;
    }
    ${aboveMobile} {
        margin-top: 22px;
    }
`;

export { Title, SubTitle };
