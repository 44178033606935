import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

interface RootProps {
    height?: number;
    open: boolean;
}

const Content = styled.div`
    height: ${(props: RootProps) => props.height}px;
    overflow: hidden;
    position: relative;
    transition: height ease-in-out 300ms;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        content: '';
        display: block;
        height: ${(props: RootProps) => (!props.open && props.height) || 0}px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 1) 100%
        );
    }
`;

const Button = styled('button')`
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
    padding: 0;
`;

interface ReadMoreProps {
    maxHeight: number;
    children: ReactNode;
    readMoreLabel: string;
    readLessLabel: string;
    contentClassName?: string;
}

function ReadMore(props: ReadMoreProps) {
    const {
        maxHeight,
        children,
        readMoreLabel,
        readLessLabel,
        contentClassName,
    } = props;

    const [isEnabled, setEnabled] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            const height = contentRef.current.scrollHeight;
            if (height > maxHeight) {
                setEnabled(true);
            }
        }
    }, []);

    function toggleReadMore() {
        setOpen(!isOpen);
    }

    const fullHeight = contentRef.current
        ? contentRef.current.scrollHeight
        : undefined;

    return (
        <div>
            <Content
                ref={contentRef}
                height={isEnabled && !isOpen ? maxHeight : fullHeight}
                open={isOpen}
                className={contentClassName}
            >
                {children}
            </Content>
            {isEnabled && (
                <Button onClick={toggleReadMore}>
                    {isOpen ? readLessLabel : readMoreLabel}
                </Button>
            )}
        </div>
    );
}

export { ReadMore };
