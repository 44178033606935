import React from 'react';
import styled from '@emotion/styled';
import { CloudinaryImage } from './CloudinaryImage';
import { TextList } from './TextList';
import { ImageWithSize } from '../types';

interface RootProps {
    width: number;
}

const Root = styled.div`
    background-color: #fcf3ed;
    width: ${(props: RootProps) => props.width}px;
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 1.625rem;
    text-decoration: underline;
    text-align: center;
`;

const BulletsList = styled(TextList)`
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: auto;
`;

const Separator = styled.div`
    border-top: 2px dashed #7f7c7b;
    margin: 0 12px 20px 12px;
`;

const Price = styled.div`
    font-size: 1.5625rem;
    text-align: center;
`;

interface PackageProps {
    name: string;
    image: ImageWithSize;
    bullets: string[];
    price: string;
    className?: string;
}

const width = 310;
function Package(props: PackageProps) {
    const { name, image, bullets, price, className } = props;
    return (
        <Root width={width} className={className}>
            <CloudinaryImage
                image={image}
                options={{ fixedWidth: width, crop: 'fill' }}
            />
            <Content>
                <Title>{name}</Title>
                <BulletsList items={bullets} />
                <Separator />
                <Price>{price}</Price>
            </Content>
        </Root>
    );
}

export { Package };
