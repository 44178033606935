import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { aboveMobile, belowEqualMobile } from '../../services/mediaquery';

const Markdown = styled(ReactMarkdown as any)`
    line-height: normal;
    p {
        margin-top: 20px;
        &:first-of-type {
            margin-top: 0;
        }
    }
    ${belowEqualMobile} {
        font-size: 1.125rem;
    }
    ${aboveMobile} {
        font-size: 1.0625rem;
    }
    strong {
        font-weight: bold;
    }
    a {
        margin-top: 30px;
        display: block;
        text-decoration: underline;
    }
`;

export { Markdown };
